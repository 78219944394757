.edit {
  font-weight: bold;
  width: 180px;
  height: 40px;
  position: absolute;
  padding: 10px 20px;
  background-color: white;
  color: black;
  border: 2px solid #eeeeee;
  border-radius: 25px;
  margin-top: 50px;
  margin-left: 380px;
}
  
.edit:hover {
  background-color: #efefef;
  cursor: pointer;
}

.popup {
  font-size: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 15px;
  width: 550px;
  height: auto;
  z-index: 999;
}

.popup > .header {
  height: auto;
  padding: 5px 10px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.close {
  font-size: 20px;
  background: none;
  border: none;
  cursor: pointer;
}

.save {
  font-size: 13px;
  display: block;
  font-weight: bold;
  width: 60px;
  padding: 3px 8px;
  background-color: black;
  color: white;
  border: 0;
  border-radius: 25px;
  cursor: pointer;
}

.save:hover {
  background-color: #2c2c2c;
}

.save:disabled {
  opacity: 0.5;
  cursor: auto;
}

.save:disabled:hover {
  background-color: black;
}

.box {
  height: auto;
  border: 1px solid #eeeeee;
  padding: 10px;
  margin: 5px;
  background-color: white;
}

.above {
  height: 20px;
  display: flex;
  align-items: center;
  padding: 2px;
}

.above > span {
  color: #8d8d8d;
  font-size: 65%;
}

.icon {
  position: relative;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 2px solid #eeeeee;
}

.iconImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: 50%;
}

.uploader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.uploader:hover {
  background-color: rgba(121, 121, 121, 0.5);
  cursor: pointer;
}

.camera {
  font-size: 20px;
  color: rgb(236, 236, 236);
}

.limit {
  margin-left: auto;
}

.name {
  display: block;
  font-size: 17px;
  border: none;
  outline: none;
  width: 100%;
}

.bio {
  font-family: arial;
  font-size: 17px;
  border: none;
  outline: none;
  resize: none;
  width: 100%;
  height: 50px;
}

.cropPopup {
  font-size: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 15px;
  width: 500px;
  height: 500px;
  z-index: 999;
}

.cropPopup > .header {
  height: auto;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.back {
  font-size: 20px;
  background: none;
  border: none;
  cursor: pointer;
}

.apply {
  font-size: 13px;
  font-weight: bold;
  width: 60px;
  padding: 3px 8px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
}

.apply:hover {
  background-color: #2c2c2c;
}

.crop {
  position: relative;
  width: 100%;
  height: 400px;
}

.area {
  border: 5px solid #ff4747 !important;
}

.bottom {
  padding-top: 15px;
  height: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.bottom > input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background: #ffacac;
  width: 300px;
  height: 4px;
  margin: 0 10px;
  border-radius: 10px;
  outline: 0;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #ff4747;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  }

  &::-moz-range-thumb {
    background: #ff4747;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
    border: none;
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &:active::-webkit-slider-thumb {
    box-shadow: 0px 5px 10px -2px rgba(0, 0, 0, 0.3);
  }
}

.magnifying {
  font-size: 18px;
  color: rgb(165, 165, 165);
}