.popupResult {
    position: fixed;
    display: flex;
    background-color: #d1484d;
    border-radius: 2px;
    top: 96%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 35px;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    opacity: 1;
}
  
.popupResult span {
    color: white;
}