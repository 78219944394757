.notice {
  position: relative;
  display: flex;
  height: auto;
  border: 2px solid #eeeeee;
  padding: 10px;
  margin: 5px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 2px 2px 5px #eeeeee;
}

.left {
  width: 63px;
}

.right {
  width: 503px;
  margin-right: 10px;
}

.heart {
  font-size: 35px;
  color: #fe4343;
  margin-top: 5px;
  margin-left: 15px;
  margin-right: 10px;
}

.follow {
  font-size: 35px;
  color: #00bfff;
  margin-top: 5px;
  margin-left: 15px;
}

.postIconContainer {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 13px;
  position: relative;
}

.notificationIconContainer {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  margin: 5px 0;
}

.iconImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: 50%;
  transition: filter 0.3s ease;
  cursor: pointer;
}

.iconImg:hover {
  filter: brightness(85%);
}

.title {
  font-size: 17px;
}

.text {
  padding-top: 8px;
}

.text span {
  font-size: 16px;
  color: #6b6b6b;
}

.displayName {
  font-weight: bold;
  color: black;
  text-decoration: none;
}

.displayName:visited {
  font-weight: bold;
  color: black;
  text-decoration: none;
}

.displayName:hover {
  color: black;
  cursor: pointer;
  text-decoration: underline;
}

.loading {
  width: 596px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}