.box {
  position: relative;
  height: auto;
  border: 2px solid #eeeeee;
  padding: 10px;
  margin: 5px;
  background-color: #ffffff;
  box-shadow: 2px 2px 5px #eeeeee;
}

.left {
  flex: 1;
}

.right {
  flex: 9;
}

.icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 13px;
}

.iconImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: 50%;
  transition: filter 0.3s ease;
  cursor: pointer;
}

.icon:hover .iconImg {
  filter: brightness(85%);
}

.form {
  display: flex;
  height: auto;
}

.form textarea {
  font-family: arial;
  font-size: 20px;
  width: 100%;
  margin-top: 8px;
  resize: none;
  border: none;
  outline: none;
  overflow-y: auto;
  height: auto;
  min-height: 50px;
  max-height: calc(60vh);
  box-sizing: border-box;
}

.bottom {
  height: 42px;
  justify-content: right;
  align-items: center;
  display: flex;
  margin: 0px 10px;
}

.submit {
  font-weight: bold;
  background-color: #ff5c62;
  color: white;
  border: none;
  padding: 8px 25px;
  border-radius: 10px;
  float: right;
}

.submit:disabled {
  opacity: 0.5;
  cursor: auto;
}

.submit:disabled:hover {
  background-color: #ff5c62;
  cursor: auto;
}

.submit:hover {
  cursor: pointer;
  background-color: #ff4052;
}