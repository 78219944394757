.button {
  font-weight: bold;
  width: 150px;
  height: 50px;
  position: fixed;
  padding: 10px 20px;
  background-color: #ff5c62;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  bottom: 0;
  left: 0;
  margin: 0px 0px 25px 115px;
  z-index: 500;
}

.button:hover {
  background-color: #ff4052;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 15px;
  max-width: 90%;
  width: 550px;
  height: auto;
  z-index: 999;
}

.left {
  flex: 1;
}

.right {
  flex: 9;
}

.icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 13px;
}

.iconImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: 50%;
}

.form {
  margin: 5px;
  padding: 10px;
  display: flex;
  height: auto;
}

.textareaDefault {
  font-family: arial;
  font-size: 20px;
  width: 100%;
  margin-top: 8px;
  resize: none;
  border: none;
  outline: none;
  overflow-y: auto;
  height: auto;
  min-height: 150px;
  max-height: calc(60vh);
  box-sizing: border-box;
}

.textareaWithImage {
  font-family: arial;
  font-size: 20px;
  width: 100%;
  margin-top: 8px;
  resize: none;
  border: none;
  outline: none;
  overflow-y: auto;
  height: auto;
  min-height: 50px;
  max-height: calc(30vh);
  box-sizing: border-box;
}

.line {
  flex: 10;
  border: 1px solid #ccc;
  margin: 10px;
}

.bottom {
  height: 42px;
  align-items: center;
  display: flex;
  margin: 0px 10px;
}

.tablist {
  flex: 8;
}

.submit {
  flex: 2;
  justify-content: right;
}

.submit button {
  font-weight: bold;
  background-color: #ff5c62;
  color: white;
  border: none;
  padding: 8px 25px;
  border-radius: 10px;
  float: right;
}

.submit button:disabled {
  opacity: 0.5;
  cursor: auto;
}

.submit button:disabled:hover {
  background-color: #ff5c62;
  cursor: auto;
}

.submit button:hover {
  cursor: pointer;
  background-color: #ff4052;
}

.previewContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.preview {
  max-width: 443px;
  max-height: 443px;
  object-fit: contain;
  border-radius: 10px;
}

.deletePreview {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 18px;
  color: rgb(247, 247, 247);
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.deletePreview:hover {
  background-color: rgba(121, 121, 121, 0.5);
  cursor: pointer;
}