* { box-sizing:border-box; }

.bodyContainer {
  font-family: Helvetica;
  background: #eee;
  -webkit-font-smoothing: antialiased;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bodyContainer hgroup { 
  text-align:center;
  margin-top: 4em;
}

.bodyContainer h1, .bodyContainer h3 { font-weight: 300; }

.bodyContainer h1 { color: #636363; }

.bodyContainer h3 { color: #ff5c62; }

.bodyContainer form {
  width: 380px;
  margin: 4em auto;
  padding: 3em 2em 2em 2em;
  background: #fafafa;
  border: 1px solid #ebebeb;
  box-shadow: rgba(0,0,0,0.14902) 0px 1px 1px 0px,rgba(0,0,0,0.09804) 0px 1px 2px 0px;
}

.group { 
  position: relative; 
  margin-bottom: 45px; 
}

.group input {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  -webkit-appearance: none;
  display: block;
  background: #fafafa;
  color: #636363;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #757575;
}

.group input:focus { outline: none; }


/* Label */

.bodyContainer label {
  color: #999; 
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: all 0.2s ease;
}

.bodyContainer span {
    color: #636363;
}

.bodyContainer span a {
    color: #ff5c62;
}

.signup {
  text-align: center;
}

.login {
  text-align: center;
}


/* active */

input:focus ~ label, input.used ~ label {
  top: -20px;
  transform: scale(.75); left: -2px;
  /* font-size: 14px; */
  color: #ff5c62;
}


/* Underline */

.bar {
  position: relative;
  display: block;
  width: 100%;
}

.bar:before, .bar:after {
  content: '';
  height: 2px; 
  width: 0;
  bottom: 1px; 
  position: absolute;
  background: #ff5c62; 
  transition: all 0.2s ease;
}

.bar:before { left: 50%; }

.bar:after { right: 50%; }


/* active */

input:focus ~ .bar:before, input:focus ~ .bar:after { width: 50%; }


/* Highlight */

.highlight {
  position: absolute;
  height: 60%; 
  width: 100px; 
  top: 25%; 
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}


/* active */

input:focus ~ .highlight {
  animation: inputHighlighter 0.3s ease;
}


/* Animations */

@keyframes inputHighlighter {
  from { background: #ff5c62; }
  to  { width: 0; background: transparent; }
}


/* Button */

.button {
  position: relative;
  display: inline-block;
  padding: 12px 24px;
  margin: .3em 0 1em 0;
  width: 100%;
  vertical-align: middle;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  letter-spacing: 1px;
  background: transparent;
  border: 0;
  border-bottom: 2px solid #e43143;
  cursor: pointer;
  transition: all 0.15s ease;
}
.button:focus { outline: 0; }


/* Button modifiers */

.buttonBlue {
  background: #ff5c62;
  text-shadow: 1px 1px 0 rgba(39, 110, 204, .5);
}

.buttonBlue:hover { background: #ff4052; }


/* Ripples container */

.ripples {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: transparent;
}


/* Ripples circle */

.ripplesCircle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: 0;
  height: 0;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.25);
}

.ripples.isActive .ripplesCircle {
  animation: ripples .4s ease-in;
}

/* Ripples animation */

@keyframes ripples {
  0% { opacity: 0; }

  25% { opacity: 1; }

  100% {
    width: 200%;
    padding-bottom: 200%;
    opacity: 0;
  }
}

.bodyContainer footer { text-align: center; }

.bodyContainer footer p {
  color: #888;
  font-size: 13px;
  letter-spacing: .4px;
}

.bodyContainer footer a {
  color: #ff5c62;
  text-decoration: none;
  transition: all .2s ease;
}

.bodyContainer footer a:hover {
  color: #666;
  text-decoration: underline;
}

.bodyContainer footer img {
  width: 80px;
  transition: all .2s ease;
}

.bodyContainer footer img:hover { opacity: .83; }

.bodyContainer footer img:focus , .bodyContainer footer a:focus { outline: none; }