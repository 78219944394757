.post {
  position: relative;
  height: auto;
  border: 2px solid #eeeeee;
  padding: 10px;
  margin: 5px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 2px 2px 5px #eeeeee;
  display: flex;
}

.post.hover:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.left {
  flex: 3;
}

.right {
  flex: 25;
  margin-right: 10px;
}

.icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 13px;
  position: relative;
}

.iconImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: 50%;
  transition: filter 0.3s ease;
  cursor: pointer;
}

.icon:hover .iconImg {
  filter: brightness(85%);
}

.detail {
  padding: 5px 0;
  max-width: 95%;
  align-items: center;
  display: flex;
}

.displayName {
  max-width: 60%;
  font-weight: bold;
  color: black;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.displayName:visited {
  font-weight: bold;
  color: black;
  text-decoration: none;
}

.displayName:hover {
  color: black;
  text-decoration: underline;
}

.timestamp {
  margin-left: 3px;
  font-size: 80%;
  color: gray;
  flex-shrink: 0;
}

.fire {
  margin-left: 4px;
  padding-bottom: 5px;
  width: 20px;
}

.title {
  font-size: 80%;
  flex: 1 1 auto;
  color: orange;
  margin-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text {
  color: black;
  font-size: 20px;
  line-height: 20px;
  white-space: pre-line;
}

.text a,a:visited{
  color: #439ff0;
}

.preview {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.previewImg {
  max-width: 100%;
  max-height: 493px;
  object-fit: contain;
  border-radius: 10px;
}