.loading {
  display: flex;
  justify-content: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-top: 40px;
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}

.profile {
display: flex;
flex-direction: column;
line-height: 0;
height: auto;
border: 2px solid #eeeeee;
padding: 10px;
margin: 5px;
background-color: #ffffff;
box-shadow: 2px 2px 5px #eeeeee;
}

.profileHeader {
display: flex;
}

.profileIconContainer {
width: 100px;
height: 100px;
border-radius: 50%;
overflow: hidden;
margin-right: 10px;
}

.editProfileButton {
font-weight: bold;
width: 180px;
height: 40px;
position: absolute;
padding: 10px 20px;
background-color: white;
color: black;
border: 2px solid #eeeeee;
border-radius: 25px;
margin-top: 50px;
margin-left: 380px;
}

.editProfileButton:hover {
background-color: #efefef;
cursor: pointer;
}

.profileMenuButton {
font-size: 16px;
width: 40px;
height: 40px;
position: absolute;
background-color: white;
color: black;
border: 2px solid #eeeeee;
border-radius: 25px;
margin-top: 50px;
margin-left: 335px;
}

.profileMenuButton:hover {
background-color: #efefef;
cursor: pointer;
}

.cancelFollowButton {
font-weight: bold;
width: 180px;
height: 40px;
position: absolute;
padding: 10px 20px;
background-color: white;
color: black;
border: 2px solid #eeeeee;
border-radius: 25px;
margin-top: 50px;
margin-left: 380px;
}

.cancelFollowButton:hover {
background-color: #efefef;
cursor: pointer;
}

.followButton {
font-weight: bold;
width: 180px;
height: 40px;
position: absolute;
padding: 10px 20px;
background-color: black;
color: white;
border: 2px solid #eeeeee;
border-radius: 25px;
margin-top: 50px;
margin-left: 380px;
}

.followButton:hover {
background-color: #2c2c2c;
cursor: pointer;
}

.profileContent {
margin-top: 5px;
margin-left: 5px;
}

.profileUserId {
color: #acacac;
font-size: 65%;
margin-top: 23px;
margin-bottom: 35px;
}

.profileBio {
font-size: 85%;
padding-bottom: 3px;
}

.profileInputText {
display: flex;
}

p#display-name-char-limit {
margin-left: auto;
}

p#biography-char-limit {
margin-left: auto;
}

.followContent {
font-weight: bold;
font-size: 65%;
margin-top: 25px;
}

.followContent span {
text-decoration: none;
}

.followContent span:hover {
cursor: pointer;
text-decoration: underline;
}

.popupMenuProfile {
display: none;
position: absolute;
list-style: none;
top: 6%;
right: 44%;
width: 170px;
background-color: #ffffff;
border: 1px solid #d9d9d9;
box-shadow: 2px 2px 5px #d9d9d9;
margin-top: 10px;
margin-right: 10px;
z-index: 999;
}

.popupMenuProfile button {
text-align: left;
width: 100%;
padding: 10px;
border: none;
background: none;
cursor: pointer;
}

.popupMenuProfile button:hover {
background-color: #f5f5f5;
}

.iconImg {
width: 100%;
height: 100%;
object-fit: cover;
object-position: center center;
border-radius: 50%;
}

.iconImg:hover {
cursor: pointer;
}

.menuReportButton {
color: red;
}

.displayName {
font-weight: bold;
color: black;
text-decoration: none;
}

.displayName:visited {
font-weight: bold;
color: black;
text-decoration: none;
}

.displayName:hover {
color: black;
text-decoration: underline;
}

.favorites {
  display: flex;
  line-height: 0;
  height: auto;
  border: 2px solid #eeeeee;
  padding: 10px;
  margin: 5px;
  background-color: #ffffff;
  box-shadow: 2px 2px 5px #eeeeee;
  justify-content: center;
  align-items: center;
}

.favorites p {
  color: gray;
  padding: 10px;
}

.card {
  width: 130px;
  aspect-ratio: 37 / 50;
  border: 1px solid black;
  margin: 5px 15px;
  box-sizing: border-box;
}