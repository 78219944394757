.post {
  position: relative;
  height: auto;
  border: 2px solid #eeeeee;
  padding: 10px;
  margin: 5px;
  background-color: #ffffff;
  box-shadow: 2px 2px 5px #eeeeee;
}

.post.hover:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.head {
  display: flex;
  padding-bottom: 5px;
}

.body {
  padding: 7px;
}

.icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 13px;
  position: relative;
}

.iconImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: 50%;
  transition: filter 0.3s ease;
  cursor: pointer;
}

.icon:hover .iconImg {
  filter: brightness(85%);
}

.detail {
  padding: 5px 0;
  align-items: center;
  display: flex;
}

.displayName {
  font-weight: bold;
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.displayName:visited {
  font-weight: bold;
  color: black;
  text-decoration: none;
}

.displayName:hover {
  color: black;
  text-decoration: underline;
}

.timestamp {
  font-size: 80%;
  color: gray;
  margin-left: 5px;
}

.text {
  color: black;
  font-size: 20px;
  line-height: 20px;
  white-space: pre-line;
}

.text a,a:visited{
  color: #439ff0;
}

.preview {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.previewImg {
  max-width: 100%;
  max-height: 493px;
  object-fit: contain;
  border-radius: 10px;
}

.loading {
  width: 596px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}