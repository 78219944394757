.button {
  font-weight: bold;
  width: 125px;
  height: 125px;
  position: fixed;
  background-color: #ff5c62;
  color: white;
  font-size: 15px;
  border: none;
  border-radius: 75px;
  cursor: pointer;
  bottom: 8%;
  left: 85%;
  z-index: 500;
}

.button:hover {
  background-color: #ff4052;
}

.card {
  position: fixed;
  width: 130px;
  aspect-ratio: 37 / 50;
  border: 1px solid black;
  box-sizing: border-box;
  bottom: 8%;
  left: 85%;
  z-index: 500;
}