.group {
  cursor: auto;
}

.toggle {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 85%;
  color: #989898;
  border: none;
  background: none;
  margin-top: 10px;
  margin-right: 10px;
}

.toggle:hover {
  color: #3f3f3f;
  cursor: pointer;
}

.menu {
  position: absolute;
  list-style: none;
  top: 0;
  right: 0;
  width: 170px;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 2px 2px 5px #d9d9d9;
  margin-top: 10px;
  margin-right: 10px;
  z-index: 999;
}

.menu > button {
  text-align: left;
  width: 100%;
  padding: 10px;
  border: none;
  background: none;
  cursor: pointer;
}

.menu > button:hover {
  background-color: #f5f5f5;
}

.report {
  color: red;
}