.delete {
  color: red;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 15px;
  max-width: 90%;
  width: 325px;
  height: auto;
  z-index: 999;
}

.title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 10px;
}

.text {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: #838383;
}

.submit {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  margin-top: 10px;
}

.submit > button {
  width: 230px;
  height: 40px;
  background-color: red;
  color: white;
  border: 0px;
  border-radius: 25px;
  font-weight: bold;
  font-size: 14px;
}

.submit > button:hover {
  background-color: rgb(214, 0, 0);
  cursor: pointer;
}

.cancel {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancel > button {
  width: 230px;
  height: 40px;
  background-color: white;
  color: black;
  border: 1px solid #e4e4e4;
  border-radius: 25px;
  font-weight: bold;
  font-size: 14px;
  box-sizing: border-box;
}

.cancel > button:hover {
  background-color: #e4e4e4;
  cursor: pointer;
}