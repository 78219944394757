.menu {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 15px;
  max-width: 90%;
  width: 700px;
  height: auto;
  z-index: 999;
}

.body {
  display: flex;
  padding: 40px 40px 0 40px;
}

.bottom {
  display: flex;
  justify-content: right;
  align-items: center;
  margin: 0 10px 10px 10px;
}

.left {
  flex: 25;
  justify-content: center;
  align-items: center;
  display: flex;
}

.right {
  flex: 75;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card {
  width: 130px;
  aspect-ratio: 37 / 50;
  border: 1px solid black;
  margin: 5px;
  box-sizing: border-box;
}

.text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
  font-size: 21px;
}

.count {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
  font-size: 20px;
}

.slider {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 4;
}

.slider > input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background: #ffacac;
  width: 300px;
  height: 4px;
  margin: 0 10px;
  border-radius: 10px;
  outline: 0;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #ff4747;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  }

  &::-moz-range-thumb {
    background: #ff4747;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
    border: none;
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &:active::-webkit-slider-thumb {
    box-shadow: 0px 5px 10px -2px rgba(0, 0, 0, 0.3);
  }
}

.start {
  font-weight: bold;
  background-color: #ff5c62;
  color: white;
  border: none;
  padding: 8px 25px;
  border-radius: 10px;
}

.start:hover {
  cursor: pointer;
  background-color: #ff4052;
}