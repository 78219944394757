.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff5c62;
}

.loading-container span{
    font-size: 100px;
    color: white;
}

.postsContainer {
    font-family: arial;
    font-size: 20px;
    margin: 25px;
    height: 100%;
    width: 600px;
}

.timelineContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
}

.blackBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  background-color: rgba(0, 0, 0, 0.5);
}

.clearBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 997;
}