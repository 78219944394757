.report {
  color: red;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 15px;
  max-width: 90%;
  width: 550px;
  height: 600px;
  z-index: 999;
  display: flex;
  flex-direction: column;
}

.popup > .header {
  height: auto;
  padding: 5px 10px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.close {
  font-size: 20px;
  background: none;
  border: none;
  cursor: pointer;
}

.list {
  height: 500px;
  overflow-y: auto;
  padding: 0 60px;
}

.list > label {
  max-width: 500px;
  display: flex;
  padding: 12px 16px 16px;
}

.title {
  margin: 0 10px;
}

.title > span {
  font-size: 32px;
  font-weight: bold;
}

.content {
  flex: 9;
}

.content > span {
  font-size: 20px;
}

.radio {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.radio > input {
  width: 20px;
  height: 20px;
}

.bottom {
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit {
  font-weight: bold;
  width: 240px;
  height: 50px;
  padding: 10px 20px;
  background-color: black;
  color: white;
  border: 2px solid #eeeeee;
  border-radius: 25px;
}

.submit:hover {
  background-color: #2c2c2c;
  cursor: pointer;
}

.submit:disabled {
  opacity: 0.5;
  cursor: auto;
}

.submit:disabled:hover {
  background-color: black;
}