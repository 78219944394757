@import url("//cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css");

.bodyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  background: #fff4f5;
  z-index: 500;
}
.menu li a{
    display:block;
    height:em;
    width:5em;
    text-indent:-500em;
    line-height:5em;
    text-align:center;
    color: #ff5c62;
    background: #fff4f5;
    position: relative;
    border-bottom: 1px solid #ffe2e3;
    transition: background 0.3s ease-in-out;
}
.menu li a:before {
  font-family: FontAwesome;
  speak-as: none;
  text-indent: 0em;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 1.4em;
}
.menu li a.accessSearch:before {
  content: "\f002";
}
.menu li a.accessBookmark:before {
  content: "\f187";
}
.menu li a.accessNotification:before {
  content: "\f0f3";
}
.menu li a.accessMessage:before {
  content: "\f003";
}
.menu li a.accessProfile:before {
  content: "\f007";
}
.menu li a.accessHome:before {
  content: "\f015";
}
.menu li a:hover{
  background: #ff5c62;
  color: #fff;
}
.menu li.current a {
  background: #ff5e5e;
  color: #fff;
}
.menu li a.active {
  background: #ff5e5e;
  color: #fff;
}
.menu li a.active:after{
    position:absolute;
    left:5em;
    top:0;
    content:"";
    border:2.5em solid transparent;
    border-left-color:#ff5c62;
    border-width: 2.5em 1em
}
.menu li{
    position:relative;
}
.menu li:after{
    content: attr(title);
    position:absolute;
    left:5em;
    top:0;
    height:5em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-transform:uppercase;
    background:#ff5c62;
    padding:2em;
    transition: all 0.3s ease-in-out;
    visibility:hidden;
    opacity:0;
}
.menu li:hover:after{
    visibility:visible;
    opacity:1;
}
@media screen and (max-height: 34em){
  .menu li{
    font-size:70%;
  }
}

.notificationsCountBadge {
  border: 2px solid #e9be00;
  border-radius: 25px;
  background-color: #fff3be;
  padding: 0px 8px;
  position: absolute;
  top: 3px;
  left: 49px;
}

.notificationsCountNumber {
  font-size: 12px;
}

.userMenu {
  position: fixed;
  list-style: none;
  bottom: 0;
  left: 0;
  width: 220px;
  background-color: hsl(0, 0%, 100%);
  border: 1px solid #d9d9d9;
  box-shadow: 2px 2px 5px #d9d9d9;
  border-radius: 8px;
  margin: 0px 0px 83px 8px;
  z-index: 999;
}

.userMenu button {
  text-align: left;
  width: 100%;
  padding: 10px;
  border: none;
  background: none;
  cursor: pointer;
}

.userMenu button:hover {
  background-color: #f5f5f5;
}

.menuIconContainer {
  position: fixed;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  bottom: 0;
  left: 0;
  margin: 0px 0px 15px 10px;
}

.iconImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: 50%;
}

.iconImg:hover {
  cursor: pointer;
}

.backgroundSidebarMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
}