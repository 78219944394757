.menu {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 15px;
  max-width: 90%;
  width: 550px;
  height: 600px;
  z-index: 999;
}

.list {
  height: 475px;
  margin: 0px 19px;
  overflow-y: auto;
}

.cards {
  display: flex;
  flex-wrap: wrap;
}

.card {
  width: 111px;
  aspect-ratio: 37 / 50;
  border: 1px solid black;
  margin: 5px;
  box-sizing: border-box;
  position: relative;
}

.card.active::after {
  content: '';
  position: absolute;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: -6px;
  border: 4px solid rgb(31, 200, 212);
}

.search {
  padding: 5px;
  margin: 10px 15px;
  background-color: #ffffff;
  box-shadow: 2px 2px 5px #eeeeee;
  border: 2px solid #eeeeee;
  border-radius: 25px;
  display: flex;
}

.searchLeft {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b8b8b8;
}

.searchRight {
  flex: 9;
}

.searchRight input {
  font-size: 15px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 25px;
  padding: 11px;
  resize: none;
  border: none;
  outline: none;
}

.bottom {
  height: 42px;
  justify-content: right;
  align-items: center;
  display: flex;
  margin: 0px 10px;
}

.next {
  font-size: 13px;
  display: block;
  font-weight: bold;
  width: 60px;
  padding: 3px 8px;
  background-color: black;
  color: white;
  border: 0;
  border-radius: 25px;
  cursor: pointer;
}

.next:hover {
  background-color: #2c2c2c;
}

.next:disabled {
  opacity: 0.5;
  cursor: auto;
}

.next:disabled:hover {
  background-color: black;
}

.loading {
  width: 484px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}