.box {
  flex: 1;
  display: flex;
  align-items: center;
}

.comment {
  color: #989898;
  border: none;
  background: none;
  padding: 0;
}

.comment:hover {
  color: #00bfff;
  cursor: pointer;
}

.current .comment {
  font-size: 20px;
}

.count {
  font-size: 12px;
  color: #989898;
  padding-left: 7px;
}

.current {
  display: flex;
  padding: 10px;
  margin: 5px;
}

.current .count {
  font-size: 15px;
  padding-left: 10px;
}

.group {
  cursor: auto;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 15px;
  max-width: 90%;
  width: 550px;
  height: auto;
  z-index: 999;
}

.left {
  flex: 1;
}

.right {
  flex: 9;
}

.line {
  flex: 10;
  border: 1px solid #ccc;
  margin: 10px;
}

.icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 13px;
}

.iconImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: 50%;
}

.name {
  font-weight: bold;
  color: black;
  text-decoration: none;
}

.content {
  white-space: pre-line;
}

.timestamp {
  font-size: 80%;
  color: gray;
  margin-left: 5px;
}

.form {
  margin: 5px;
  padding: 25px 10px 0px 10px;
  display: flex;
  height: auto;
}

.form textarea {
  font-family: arial;
  font-size: 20px;
  width: 100%;
  margin-top: 8px;
  resize: none;
  border: none;
  outline: none;
  overflow-y: auto;
  height: auto;
  min-height: 150px;
  max-height: calc(60vh);
  box-sizing: border-box;
}

.bottom {
  height: 42px;
  justify-content: right;
  align-items: center;
  display: flex;
  margin: 0px 10px;
}

.submit {
  font-weight: bold;
  background-color: #ff5c62;
  color: white;
  border: none;
  padding: 8px 25px;
  border-radius: 10px;
  float: right;
}

.submit:disabled {
  opacity: 0.5;
  cursor: auto;
}

.submit:disabled:hover {
  background-color: #ff5c62;
  cursor: auto;
}

.submit:hover {
  cursor: pointer;
  background-color: #ff4052;
}